body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* App.css */

/* App.css */

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  user-select: none;
}

.BackgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.Desktop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  padding: 10px;
  width: 100vw;
  height: 100vh;
}

.Icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
  text-decoration: none;
  color: black;
  height: 80px;
  width: 80px;
  cursor: pointer;
}

.Icon img {
  width: 70px;
  height: 70px;
}

.Icon p {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
}

.Taskbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #202020;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 1;
}

.start-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 340px;
  height: 580px;
  background: rgba(0, 0, 0, 0.8);
  will-change: transform, opacity;
  z-index: 1;
  border-radius: 5px;
  overflow-y: scroll;
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: #313131;
  /* Change this to your preferred color */
}

.desktop-app {
  background-color: #202020;
  position: absolute;
  height: 100%;
}

.desktop-app-header {
  background-color: #202020;
  display: flex;
  align-items: center;
  padding: 4px;
}

::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none;
}

.rw-container-main {
  border-radius: 0px 0px 0px 0px !important;
}

.css-evb4g3 {
  border-radius: 0px 0px 0px 0px !important;
}

.terminal-container {
  user-select: text;
}


@tailwind base;
@tailwind components;
@tailwind utilities;